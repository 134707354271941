.pwdIcon {
  position: relative;
}
.pwdIcon img {
  cursor: pointer;
  position: absolute;
  width: 25px;
  right: 21px;
  top: 25px;
}
