body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  padding: 0px 16px;
}
:root{
  font-family: 'Vazir', sans-serif !important;
}
.css-f3jnds-MuiDataGrid-columnHeaders {
  background-color: #90caf9;
}
.css-ptiqhd-MuiSvgIcon-root {
  display: none !important;
}
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-size: large !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bolder !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1em !important;
}
.css-i4bv87-MuiSvgIcon-root{
font-size: 1.5rem !important;
padding-left:5px;
}
