.success-msg{
    width: 100%;
    background-color: green;
    color: #fff;
    font-weight: 600;
    padding: 20px;
}
.error-msg{
    font: 12px;
    width: 100%;
    color: red;
    padding-top: 8px;
    text-align: start;
}
/* .preloader {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    z-index: 99999;
    background: #fff
}

.preloader .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px)
}

.loader,
.loader__figure {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.loader {
    overflow: visible;
    padding-top: 2em;
    height: 0;
    width: 2em
}

.loader__figure {
    height: 0;
    width: 0;
    box-sizing: border-box;
    border: 0 solid #1976d2;
    border-radius: 50%;
    -webkit-animation: loader-figure 1.15s infinite cubic-bezier(.215, .61, .355, 1);
    -moz-animation: loader-figure 1.15s infinite cubic-bezier(.215, .61, .355, 1);
    animation: loader-figure 1.15s infinite cubic-bezier(.215, .61, .355, 1)
}

.loader__label {
    float: left;
    margin-left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: .5em 0 0 50%;
    font-size: .875em;
    letter-spacing: .1em;
    line-height: 1.5em;
    color: #1976d2;
    white-space: nowrap;
    -webkit-animation: loader-label 1.15s infinite cubic-bezier(.215, .61, .355, 1);
    -moz-animation: loader-label 1.15s infinite cubic-bezier(.215, .61, .355, 1);
    animation: loader-label 1.15s infinite cubic-bezier(.215, .61, .355, 1)
}

@-webkit-keyframes loader-figure {
    0% {
        height: 0;
        width: 0;
        background-color: #1976d2
    }

    29% {
        background-color: #1976d2
    }

    30% {
        height: 2em;
        width: 2em;
        background-color: transparent;
        border-width: 1em;
        opacity: 1
    }

    100% {
        height: 2em;
        width: 2em;
        border-width: 0;
        opacity: 0;
        background-color: transparent
    }
}

@-moz-keyframes loader-figure {
    0% {
        height: 0;
        width: 0;
        background-color: #1976d2
    }

    29% {
        background-color: #1976d2
    }

    30% {
        height: 2em;
        width: 2em;
        background-color: transparent;
        border-width: 1em;
        opacity: 1
    }

    100% {
        height: 2em;
        width: 2em;
        border-width: 0;
        opacity: 0;
        background-color: transparent
    }
}

@keyframes loader-figure {
    0% {
        height: 0;
        width: 0;
        background-color: #1976d2
    }

    29% {
        background-color: #1976d2
    }

    30% {
        height: 2em;
        width: 2em;
        background-color: transparent;
        border-width: 1em;
        opacity: 1
    }

    100% {
        height: 2em;
        width: 2em;
        border-width: 0;
        opacity: 0;
        background-color: transparent
    }
}

@-webkit-keyframes loader-label {
    0% {
        opacity: .25
    }

    30% {
        opacity: 1
    }

    100% {
        opacity: .25
    }
}

@-moz-keyframes loader-label {
    0% {
        opacity: .25
    }

    30% {
        opacity: 1
    }

    100% {
        opacity: .25
    }
}

@keyframes loader-label {
    0% {
        opacity: .25
    }

    30% {
        opacity: 1
    }

    100% {
        opacity: .25
    }
} */
