.empty{
    text-align: center;
    color:  red;
}
.error{
    color: red;
    text-align: left;
}
.loader{
    position: inherit;
    width: 90%;
    height: 557px;
    z-index: 0;
}