.permission{
 padding-left: 80px;
 height: 30px;
} 
.error{
    color: red;
   }

Input[type="number"]{
    padding: 18px !important;
    text-align: left !important;

}