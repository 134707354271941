.active{
    background-color: whitesmoke;
 
   border-radius: 20px;
     text-align: center;
  
     font-size: 17px;
} 
#active{
    background-color:#90caf9;
    border-radius: 20px;
    color: black;
} 
 